import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import _defineProperty from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import _objectWithoutProperties from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import React from 'react';
import Header from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import { NextSeo } from 'next-seo';
import { AuthProvider } from '../context/auth-context';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '@/containers/ErrorHandler';
import { useRouter } from 'next/router';

var Layout = function Layout(_ref) {
  var children = _ref.children,
      blackNavbar = _ref.blackNavbar,
      withoutFooter = _ref.withoutFooter,
      fixedHeader = _ref.fixedHeader,
      props = _objectWithoutProperties(_ref, ["children", "blackNavbar", "withoutFooter", "fixedHeader"]);

  var router = useRouter();

  var onErrorTryAgain = function onErrorTryAgain() {
    router.reload();
  };

  return _jsxs(_Fragment, {
    children: [_jsx(NextSeo, _objectSpread({
      title: "Your gateway into the NFT world.",
      description: "Easy way to buy your first NFT, and track your portfolio of digital collectibles.",
      additionalLinkTags: [{
        rel: 'icon',
        href: '/AWSt_Transparent_Logo_White.png'
      }]
    }, props)), _jsx(AuthProvider, {
      children: _jsx("main", {
        className: "flex flex-col justify-between min-h-screen page",
        children: _jsxs(ErrorBoundary, {
          FallbackComponent: ErrorFallback,
          onReset: onErrorTryAgain,
          children: [_jsx(Header, {
            blackNavbar: blackNavbar,
            fixedHeader: fixedHeader
          }), children, !withoutFooter && _jsx(Footer, {})]
        })
      })
    })]
  });
};

export default Layout;