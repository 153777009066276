import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import _defineProperty from "/trader/node_modules/next/node_modules/@babel/runtime/helpers/esm/defineProperty";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import moment from 'moment';
import Link from 'next/link';
var navigation = {
  main: [{
    name: 'Clients',
    href: ''
  }, {
    name: 'Artists',
    href: ''
  }, {
    name: 'Artworks',
    href: ''
  }, {
    name: 'FAQ',
    href: ''
  }, {
    name: 'About',
    href: ''
  }],
  social: [{
    name: 'Facebook',
    href: 'https://www.facebook.com/awstofficial',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/Facebook Icon.svg",
        alt: "Facebook Icon"
      }, props));
    }
  }, {
    name: 'Instagram',
    href: 'https://www.instagram.com/awstofficial/',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/Instagram Icon.svg",
        alt: "Instagram Icon"
      }, props));
    }
  }, {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/company/awst',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/LinkedIn Icon.svg",
        alt: "LinkedIn Icon"
      }, props));
    }
  }, {
    name: 'Medium',
    href: 'https://medium.com/awst',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/Medium Icon.svg",
        alt: "Medium Icon"
      }, props));
    }
  }, {
    name: 'Twitter',
    href: 'https://twitter.com/awstofficial',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/Twitter Icon.svg",
        alt: "Twitter Icon"
      }, props));
    }
  }, {
    name: 'Youtube',
    href: 'https://www.youtube.com/channel/UC8IwJj_ETVUGIfXrBuQfZrg',
    icon: function icon(props) {
      return _jsx("img", _objectSpread({
        src: "/images/footer/Youtube Icon.svg",
        alt: "Youtube Icon"
      }, props));
    }
  }]
};

var Footer = function Footer() {
  return _jsx("footer", {
    className: "text-white bg-black",
    children: _jsxs("div", {
      className: "container px-4 py-6 mx-auto overflow-hidden xl:max-w-screen-xl sm:px-6 lg:px-8",
      children: [_jsx("div", {
        className: "flex flex-row items-center justify-center my-10 md:justify-end space-x-3 md:space-x-5",
        children: navigation.social.map(function (item) {
          return _jsxs("a", {
            href: item.href,
            target: "_blank",
            className: "text-gray-500 hover:text-gray-500",
            children: [_jsx("span", {
              className: "sr-only",
              children: item.name
            }), _jsx(item.icon, {
              className: "w-6 h-6",
              "aria-hidden": "true"
            })]
          }, item.name);
        })
      }), _jsxs("div", {
        className: "text-sm text-center text-white",
        children: [_jsxs("span", {
          className: "px-2",
          children: ["\u24D2 ", moment().year(), " AWST"]
        }), _jsx("span", {
          className: "hidden md:inline",
          children: " | "
        }), _jsx("br", {
          className: "inline md:hidden"
        }), _jsx(Link, {
          href: "mailto:%74%65%61%6d@%61%72%74%77%61%6c%6c%73%74.%63%6f%6d",
          passHref: true,
          children: _jsx("a", {
            className: "px-2",
            children: "team@artwallst.com"
          })
        }), "|", _jsx(Link, {
          href: "/terms",
          passHref: true,
          children: _jsx("a", {
            className: "px-2",
            children: "Terms of Use"
          })
        }), _jsx("span", {
          className: "hidden md:inline",
          children: " | "
        }), _jsx("br", {
          className: "inline md:hidden"
        }), _jsx(Link, {
          href: "/privacy",
          passHref: true,
          children: _jsx("a", {
            className: "px-2",
            children: "Privacy Policy"
          })
        })]
      })]
    })
  });
};

export default Footer;