import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import React from 'react';

var ErrorFallback = function ErrorFallback(_ref) {
  var error = _ref.error,
      resetErrorBoundary = _ref.resetErrorBoundary;
  return _jsxs("div", {
    role: "alert",
    className: "container mx-auto mt-16",
    children: [_jsx("h1", {
      className: "text-lg mb-6",
      children: "Something went wrong"
    }), _jsx("pre", {
      children: error.message
    }), _jsx("button", {
      className: "btn-primary px-3 py-2 mt-4",
      onClick: resetErrorBoundary,
      children: "Try again"
    })]
  });
};

export default ErrorFallback;