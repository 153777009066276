import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { LogoutIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Link from 'next/link';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import useAuth from '../../hooks/use-auth';
import { AuthFluent } from '../../lib/helpers/authFluent';
import { useRouter } from 'next/router';
var navs = [{
  id: 'home',
  link: '/',
  label: 'Home'
}, {
  id: 'investor',
  link: '/dashboard',
  label: 'My NFTs',
  isAuth: true
}];

var Header = function Header(_ref) {
  var blackNavbar = _ref.blackNavbar,
      fixedHeader = _ref.fixedHeader;

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var router = useRouter();

  var _useState = useState(false),
      showStickyHeader = _useState[0],
      setShowStickyHeader = _useState[1];

  useScrollPosition(function (_ref2) {
    var currPos = _ref2.currPos;
    setShowStickyHeader(currPos.y < -300);
  });

  var getUserIdentity = function getUserIdentity() {
    var _user$name;

    return user ? (_user$name = user.name) !== null && _user$name !== void 0 ? _user$name : user.email : 'User';
  };

  var handleLogout = function handleLogout(e) {
    e.preventDefault();
    AuthFluent.empty().removeToken();
    router.reload();
  };

  return _jsx("div", {
    className: classNames('shadow top-0 bg-opacity-0 z-40 w-full', fixedHeader ? showStickyHeader ? 'fixed' : 'absolute' : showStickyHeader && 'sticky', showStickyHeader || blackNavbar ? 'bg-dark' : 'bg-opacity-50 bg-black'),
    children: _jsx("div", {
      className: "container mx-auto xl:max-w-screen-xl",
      children: _jsx("div", {
        className: "relative flex justify-between h-16 px-4",
        children: _jsxs("div", {
          className: "flex flex-row items-center justify-between w-full",
          children: [_jsxs("div", {
            className: "flex flex-row items-center justify-between",
            children: [_jsx("div", {
              className: "flex items-center flex-shrink-0",
              children: _jsx("a", {
                href: "https://www.awst.io",
                children: _jsx("img", {
                  className: "h-14 w-14",
                  src: "/AWSt_Transparent_Logo_White.png",
                  alt: "artwallst-logo"
                })
              })
            }), _jsx("div", {
              className: "hidden sm:ml-12 lg:flex sm:space-x-6",
              children: navs.filter(function (item) {
                return !item.isAuth || !!user;
              }).map(function (n) {
                return _jsx(Link, {
                  href: n.link,
                  passHref: true,
                  children: _jsx("a", {
                    className: classNames('btn-header-menu border-transparent text-white inline-flex items-center px-0 py-1 border-b-2 text-base font-semibold uppercase', {
                      'btn-header-menu--active': n.link === router.pathname
                    }),
                    children: n.label
                  })
                }, n.id);
              })
            })]
          }), user && _jsx("div", {
            className: "hidden lg:flex",
            children: _jsxs(Menu, {
              as: "div",
              className: "relative ml-3",
              children: [_jsx("div", {
                className: "flex items-center",
                children: _jsxs(Menu.Button, {
                  className: "flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500",
                  children: [_jsx("span", {
                    className: "sr-only",
                    children: "Open user menu"
                  }), _jsx("img", {
                    className: "w-8 h-8 rounded-full",
                    src: user.avatar && user.avatar !== '' ? user.avatar : '/images/user.svg',
                    alt: user.name
                  })]
                })
              }), _jsx(Transition, {
                as: Fragment,
                enter: "transition ease-out duration-200",
                enterFrom: "transform opacity-0 scale-95",
                enterTo: "transform opacity-100 scale-100",
                leave: "transition ease-in duration-75",
                leaveFrom: "transform opacity-100 scale-100",
                leaveTo: "transform opacity-0 scale-95",
                children: _jsx(Menu.Items, {
                  className: "absolute right-0 w-48 py-1 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none",
                  children: _jsx(Menu.Item, {
                    children: function children(_ref3) {
                      var active = _ref3.active;
                      return _jsx("a", {
                        href: "#",
                        onClick: handleLogout,
                        className: classNames(active ? 'bg-gray-100' : '', 'block px-4 py-3 text-sm text-gray-700'),
                        children: "Sign out"
                      });
                    }
                  })
                })
              })]
            })
          }), _jsx(Menu, {
            as: "div",
            className: "ml-3 lg:hidden",
            children: function children(_ref4) {
              var open = _ref4.open;
              return _jsxs(_Fragment, {
                children: [_jsx("div", {
                  children: _jsxs(Menu.Button, {
                    className: "flex text-sm text-white bg-opacity-0 focus:outline-none focus:ring-0 focus:ring-white",
                    children: [_jsx("span", {
                      className: "sr-only",
                      children: "Open user menu"
                    }), open ? _jsx(XIcon, {
                      className: "block w-6 h-6",
                      "aria-hidden": "true"
                    }) : _jsx(MenuIcon, {
                      className: "block w-6 h-6",
                      "aria-hidden": "true"
                    })]
                  })
                }), _jsx(Transition, {
                  show: open,
                  as: Fragment,
                  enter: "transition ease-out duration-200",
                  enterFrom: "transform opacity-0 scale-95",
                  enterTo: "transform opacity-100 scale-100",
                  leave: "transition ease-in duration-75",
                  leaveFrom: "transform opacity-100 scale-100",
                  leaveTo: "transform opacity-0 scale-95",
                  children: _jsxs(Menu.Items, {
                    "static": true,
                    className: "absolute right-0 w-full py-2 mt-6 bg-white rounded shadow-lg origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none",
                    children: [!user && _jsxs(_Fragment, {
                      children: [navs.filter(function (item) {
                        return !item.isAuth || !!user;
                      }).map(function (n) {
                        return _jsx(Menu.Item, {
                          children: function children() {
                            return _jsx("div", {
                              className: "block px-4 py-2",
                              children: _jsx("a", {
                                href: n.link,
                                target: n.link.includes('https') ? '_blank' : undefined,
                                className: classNames('inline-block px-2 py-2 text-sm text-gray-700', {
                                  'bg-yellow-200 text-gray-700': n.link === router.pathname
                                }),
                                children: n.label
                              })
                            });
                          }
                        }, "menu-".concat(n.id));
                      }), _jsx("div", {
                        className: "w-full h-1 my-2 border-b"
                      }), _jsx(Menu.Item, {
                        children: function children(_ref5) {
                          var active = _ref5.active;
                          return _jsx(Link, {
                            href: "/login",
                            passHref: true,
                            children: _jsx("a", {
                              className: classNames(active ? 'bg-indigo-50 border-indigo-00' : '', 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-red-400 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'),
                              children: "Sign in"
                            })
                          });
                        }
                      }, "login"), _jsx(Menu.Item, {
                        children: function children(_ref6) {
                          var active = _ref6.active;
                          return _jsx(Link, {
                            href: "/sign-up",
                            passHref: true,
                            children: _jsx("a", {
                              className: classNames(active ? 'bg-indigo-50 border-red-500' : '', 'border-transparent text-gray-500 hover:bg-gray-50 hover:border-red-400 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'),
                              children: "Sign up"
                            })
                          });
                        }
                      }, "signup")]
                    }), user && _jsxs(_Fragment, {
                      children: [navs.map(function (n) {
                        return _jsx(Menu.Item, {
                          children: function children() {
                            return _jsx("div", {
                              className: "block px-4 py-2",
                              children: _jsx("a", {
                                href: n.link,
                                target: n.link.includes('https') ? '_blank' : undefined,
                                className: classNames('inline-block px-2 py-2 text-sm text-gray-700', {
                                  'bg-yellow-200 text-gray-700': n.link === router.pathname
                                }),
                                children: n.label
                              })
                            });
                          }
                        }, "menu-".concat(n.id));
                      }), _jsx("div", {
                        className: "w-full h-1 my-2 border-b"
                      }), _jsxs("div", {
                        className: "flex flex-row items-center block px-4 py-2 text-sm text-gray-700",
                        children: [_jsx("img", {
                          className: "w-8 h-8 mr-2 rounded-full",
                          src: user.avatar && user.avatar !== '' ? user.avatar : '/images/user.svg',
                          alt: user.name
                        }), ' ', _jsx("span", {
                          className: "font-semibold",
                          children: getUserIdentity()
                        })]
                      }), _jsx("div", {
                        className: "flex flex-row items-center block px-4 py-2 text-sm text-gray-700",
                        children: _jsx(Link, {
                          href: "/profile",
                          passHref: true,
                          children: _jsx("span", {
                            style: {
                              cursor: 'pointer'
                            },
                            children: "Account Details"
                          })
                        })
                      }), _jsx(Menu.Item, {
                        children: function children(_ref7) {
                          var active = _ref7.active;
                          return _jsxs("div", {
                            className: "flex items-center flex-grow py-2 pl-6",
                            children: [_jsx(LogoutIcon, {
                              className: "w-5 h-5"
                            }), _jsx("a", {
                              href: "#",
                              onClick: handleLogout,
                              className: classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700'),
                              children: "Log out"
                            })]
                          });
                        }
                      })]
                    })]
                  })
                })]
              });
            }
          })]
        })
      })
    })
  });
}; // @ts-ignore


export default Header;