import { useContext } from 'react';
import { AuthContext } from '../context/auth-context';

var useAuth = function useAuth() {
  var auth = useContext(AuthContext);
  return {
    user: auth.user
  };
};

export default useAuth;