var getEnv = function getEnv() {
  return {
    BASE_URL: true ? window.__ENV.REACT_APP_BASE_DOMAIN : // : 'http://localhost:10202',
    process.env.REACT_APP_BASE_DOMAIN,
    BASE_API_URL: true ? window.__ENV.REACT_APP_BASE_API_DOMAIN : // : 'http://localhost:10100',
    process.env.REACT_APP_BASE_API_DOMAIN,
    DEBUG_MODE: true ? window.__ENV.REACT_APP_DEBUG : process.env.REACT_APP_DEBUG,
    SEGMENT_CODE: process.env.NEXT_PUBLIC_SEGMENT_CODE,
    MAGIC_LINK_KEY: true ? window.__ENV.REACT_APP_MAGIC_LINK_KEY : process.env.REACT_APP_MAGIC_LINK_KEY,
    CIRCLE_CALLBACK_URL: true ? window.__ENV.REACT_APP_CIRCLE_CALLBACK_URL : // : 'http://localhost:10202/account/verify',
    process.env.REACT_APP_CIRCLE_CALLBACK_URL,
    RPC_URL: true ? window.__ENV.REACH_APP_RPC_URL : // : 'https://rpc-mumbai.matic.today/',
    process.env.REACH_APP_RPC_URL,
    COMET_APP_ID: true ? window.__ENV.REACT_APP_COMET_APP_ID : process.env.REACT_APP_COMET_APP_ID,
    COMET_REGION: true ? window.__ENV.REACT_APP_COMET_REGION : process.env.REACT_APP_COMET_REGION,
    COMET_AUTH_KEY: true ? window.__ENV.REACT_APP_COMET_AUTH_KEY : process.env.REACT_APP_COMET_AUTH_KEY,
    POLYGON_DOMAIN: true ? window.__ENV.REACT_APP_POLYGON_DOMAIN : process.env.REACT_APP_POLYGON_DOMAIN,
    MAGIC_LINK_CALLBACK_URL: true ? window.__ENV.REACT_APP_MAGIC_LINK_CALLBACK_URL : process.env.REACT_APP_MAGIC_LINK_CALLBACK_URL,
    VERIFF_PUBLISHABLE_KEY: true ? window.__ENV.REACT_APP_VERIFF_PUBLISHABLE_KEY : process.env.REACT_APP_VERIFF_PUBLISHABLE_KEY
  };
};

export default getEnv;